import colors$1 from './light.js';
import colors$2 from './light_high_contrast.js';
import colors$3 from './light_colorblind.js';
import colors$4 from './light_tritanopia.js';
import colors$5 from './dark.js';
import colors$6 from './dark_dimmed.js';
import colors$7 from './dark_high_contrast.js';
import colors$8 from './dark_colorblind.js';
import colors$9 from './dark_tritanopia.js';

const colors = {
  light: colors$1,
  // eslint-disable-next-line camelcase
  light_high_contrast: colors$2,
  // eslint-disable-next-line camelcase
  light_colorblind: colors$3,
  // eslint-disable-next-line camelcase
  light_tritanopia: colors$4,
  dark: colors$5,
  dark_dimmed: colors$6,
  // eslint-disable-next-line camelcase
  dark_high_contrast: colors$7,
  // eslint-disable-next-line camelcase
  dark_colorblind: colors$8,
  // eslint-disable-next-line camelcase
  dark_tritanopia: colors$9
};

export { colors };
